import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const save = function (cond={}) {
    return Axios.post('/admin/landUseInfo/save', cond);
};

export const findByPage = function (cond={}) {
    return Axios.post('/admin/landUseInfo/findByPage', cond);
};

export const findByList = function () {
    return Axios.post(`/admin/landUseInfo/findByList`);
};

export const getById = function (id) {
    return Axios.post(`/admin/landUseInfo/getById/${id}`);
};

export const del = function (cond={}) {
    return Axios.post('/admin/landUseInfo/delete', cond);
};

export const deleteParcelInfo = function (cond={}) {
    return Axios.post('/admin/landUseInfo/deleteParcelInfo', cond);
};

export const deleteLandSituation = function (cond={}) {
    return Axios.post('/admin/landUseInfo/deleteLandSituation', cond);
};

export const deleteImg = function (cond={}) {
    return Axios.post('/admin/landUseInfo/deleteImg', cond);
};
