import Axios from '@/axios'


export const findList = function (cond={}) {
  return Axios.post('/admin/layerCatalog/findList', cond);
}
export const del = function (cond={}) {
    return Axios.post('/admin/layerCatalog/delete', cond);
}
export const save = function (cond={}) {
    return Axios.post('/admin/layerCatalog/save', cond);
}
export const batchSort = function (cond={}) {
    return Axios.post('/admin/layerCatalog/batchSort', cond);
}
export const batchSave = function (cond={}) {
    return Axios.post('/admin/layerCatalog/batchSave', cond);
}
