import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'


// 删除
export const del = function (cond={}) {
    return Axios.post('/admin/investmentPolicy/delete', cond);
}

// 查询
export const findByList = function (cond={}) {
    return Axios.post('/admin/investmentPolicy/findByList',cond);
}

// 保存
export const save = function (cond={}) {
    return Axios.post('/admin/investmentPolicy/save', cond);
}

// 查询
export const getById = function (id) {
    return Axios.post(`/admin/investmentPolicy/getById/${id}`);
}
