import Axios from '@/axios'


// 获取登录验证的信息
export const findAllKeys = function (cond={}) {
  return Axios.post('/admin/systemConfig/findAllKeys', cond);
}

// 退出登录
export const save = function (cond={}) {
  return Axios.post('/admin/systemConfig/save', cond);
}
