const cache = {
  namespaced: true,
  state: {
    data:{
        level:['省级','市级','区级','镇级']
    },
  },
  mutations: {
    ADD_DATA: (state, payload) => {
      var data = state.data[payload.key];

      if(!data.includes(payload.data)){
          data.push(payload.data)
      }
    },
  },
  actions: {
    addData({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('ADD_DATA',{data});
        resolve();
      });
    },
  }
}

export default cache
