export const SERVICEADDRESS = {
    development:{

        admin:'https://msfw.huitukj.com/admin',
        api:'https://msfw.huitukj.com/api',
        annex:'https://msfw.huitukj.com/upload',
    },
    production:{
        admin:'/admin',
        api: '/api',
        annex: '/annex',
    },
}
