import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const statisticsByTown = function (cond={}) {
    return Axios.post('/admin/statistics/statisticsByTown', cond);
};
export const findByPage = function (cond={}) {
    return Axios.post('/admin/statistics/findByPage', cond);
};
export const statisticsAreaByTown = function (cond={}) {
    return Axios.post('/admin/statistics/statisticsAreaByTown', cond);
};
export const statisticsCountByTown = function (cond={}) {
    return Axios.post('/admin/statistics/statisticsCountByTown', cond);
};
