import Vue from 'vue'
import {SERVICEADDRESS} from '@/config'
Vue.filter("dateFormat", function (value, fmt) {
    if (value) {
        if (fmt) {

            if (typeof value == 'string') {
                value = value.replace(/-/g, '/');
            }

            return new Date(value).format(fmt);
        } else {
            return new Date(value).format('yyyy-MM-dd');
        }
    } else {
        return '';
    }
});

Vue.filter("portrait", function (value) {

    return value ? value : require("@/assets/images/portrait.png");

});

Vue.filter("fileAddress", function (path) {

    return path?`${SERVICEADDRESS[process.env.NODE_ENV].annex}/${path}`:'';

});

Vue.filter("dict", function (value, data, key = 'val') {

    if (value) {
        for (let i = 0; i < data.length; i++) {
            if (data[i][key] == value) {
                value = data[i].name;
            }
        }
        return value;
    } else {
        return '';
    }

});
Vue.filter("cascader", function (value, data, key = 'val',label = 'name',separator = '/') {

    if (value) {

        var arr = [];

        function getParent(item){
            for (let i = 0; i < data.length; i++) {
                let n = data[i];
                if(n.id==item.parentId){
                    arr.unshift(n[label]);
                    if(n.parentId!=0){
                        getParent(n);
                    }
                }
            }
        }

        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            if (item[key] == value) {
                arr.push(item[label]);
                if(item.parentId!=0){
                    getParent(item);
                }
            }
        }
        return arr.join(separator);
    } else {
        return '';
    }

});

