import Axios from '@/axios'

export const findList = function (cond={}) {
    return Axios.post('/admin/layer/findList', cond);
}

export const del = function (cond={}) {
    return Axios.post('/admin/layer/delete', cond);
}
export const save = function (cond={}) {
    return Axios.post('/admin/layer/save', cond);
}
export const batchSort = function (cond={}) {
    return Axios.post('/admin/layer/batchSort', cond);
}
