import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findPage = function (cond={}) {
  return Axios.post('/admin/metadataTable/findPage', cond);
}

export const save = function (cond={}) {
  return Axios.post('/admin/metadataTable/save', cond);
}

export const del = function (cond={}) {
  return Axios.post('/admin/metadataTable/delete', cond);
}

export const findByTableName = function (cond={}) {
  return Axios.post('/admin/metadataTable/findByTableName', cond);
}

export const createTable = function (cond) {
  return Axios.post('/admin/metadataTable/createTable/' + cond);
}

export const getField = function (cond) {
  return Axios.post('/admin/metadataTable/getField/' + cond);
}
export const alterColumn = function (cond) {
  return Axios.post('/admin/metadataTable/alterColumn/' + cond);
}
