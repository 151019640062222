import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'

export const findBySystemLayer = function (id) {
  return Axios.post(`/admin/system/findBySystemLayer/${id}`);
}

export const del = function (cond={}) {
    return Axios.post('/admin/system/delete', cond);
}
export const findPage = function (cond={}) {
    return Axios.post('/admin/system/findPage', cond);
}
export const save = function (cond={}) {
    return Axios.post('/admin/system/save', cond);
}
export const saveSystemLayer = function (cond={}) {
    return Axios.post('/admin/system/saveSystemLayer', cond);
}

// 根据图层名称查询系统图层关系
export const findBySystemName = function (id) {
    return Axios.post(`/admin/system/findBySystemName/${id}`);
}
