import Axios from '@/axios'

export const findPage = function (cond={}) {
  return Axios.post('/admin/role/findPage', cond);
}


export const save = function (cond={}) {
  return Axios.post('/admin/role/save', cond);
}

export const del = function (cond) {
  return Axios.post('/admin/role/delete' , cond);
}


export const saveMenuRole = function (cond={}) {
  return Axios.post('/admin/role/saveMenuRole',cond);
}


