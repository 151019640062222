
const user = {
  namespaced: true,
  state: {
    userInfo:localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):{},
    menus: [],
    loadMenus: false
  },
  mutations: {
    SET_USER_INFO: (state, payload) => {
      if(payload.userInfo){
        localStorage.setItem('userInfo', JSON.stringify(payload.userInfo));
        state.userInfo = payload.userInfo;
      };
    },
    SET_MENUS: (state, payload) => {
      state.menus = payload.menus;
    },
    SET_LOAD_MENUS: (state, payload) => {
      state.loadMenus = payload.loadMenus;
    },
    SIGN_OUT: (state) => {
      state.userInfo = {};
      state.roles = [];
      state.menus = [];
      state.loadMenus = false;
      localStorage.removeItem('userInfo');
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        commit('SET_USER_INFO',{userInfo});
        resolve();
      });
    },
    setLoadMenus({ commit }, loadMenus) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS',{loadMenus});
        resolve();
      });
    },
  }
}

export default user
