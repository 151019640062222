const modulesFiles = require.context('./controller', true, /\.js/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {

    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const path = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    modules[moduleName] =  require(`./controller/${path}.js`)
    return modules
}, {})

const api = {
    ...modules,
}
export default api;
