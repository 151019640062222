import Axios from '@/axios'


// 获取登录验证的信息
export const login = function (cond={}) {
  return Axios.post('/admin/login', cond);
}

// 退出登录
export const loginOut = function (cond={}) {
  return Axios.post('/admin/loginOut', cond);
}