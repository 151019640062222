import Axios from '@/axios'
import {Message} from "element-ui";
import store from '@/store'
// 获取用户列表
export const findPage = function (cond={}) {
  return Axios.post('/admin/user/findPage', cond);
}



// 保存/修改用户信息
export const save = function (cond={}) {
  return Axios.post('/admin/user/save', cond);
}

// 删除用户
export const del = function (cond={}) {
  return Axios.post('/admin/user/delete', cond);
}

// 根据用户id查找角色(获取关联信息)
export const findRoleByUserId = function (cond) {
  return Axios.get('/admin/user/findRoleByUserId/' + cond);
}

// 保存用户角色关联
export const saveUserRole = function (cond={}) {
  return Axios.post('/admin/user/saveUserRole', cond);
}


export const turnOver = function (cond={}) {

  var form = new FormData();
  for(let k in cond){
    form.append(k, cond[k]);
  };
  return Axios.post('/admin/user/turnOver', form);
}

export const findByDept = function (cond) {
  return Axios.post('/admin/user/findByDept/' + cond);
}


export const getUsers = function (cache=false) {

  return new Promise((resolve,reject) => {

      var code = 'USER';

      if(cache&&store.state.dict.data[code]){
        resolve(store.state.dict.data[code])
      }else {
        Axios.get(`/admin/user/getUsers`).then(res=>{

          store.commit({
            type: 'dict/SET_DATA',
            data:res,
            code,
          });
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      }
    });

};
